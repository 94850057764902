<template>
  <div v-if="discountPercentage" class="product-tags sale">
    {{ discountPercentage }}
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { usePriceStore } from '@/stores/price-store.js';
import { storeToRefs } from 'pinia';

const priceStore = usePriceStore();
const { loggedPrices } = storeToRefs(priceStore);

const props = defineProps({
  productId: {
    required: true,
    type: Number
  },
  productPriceSpecial: {
    required: true,
    type: Number
  },
  productPriceRaw: {
    required: true,
    type: Number
  }
});
const priceOld = ref(props.productPriceRaw);
const priceNew = ref(props.productPriceSpecial);

const discountPercentage = computed(() => {
  let discountDecimals = (priceOld.value - priceNew.value) / priceOld.value;
  let discount = Math.round(discountDecimals * 100);
  if (discount < 1) {
    return;
  }
  return `-${discount}%`;
});

const updateOldPriceWithLoggedPrice = () => {
  let logged = priceStore.loggedPrices.filter(item => item.product_id == props.productId);
  if (logged.length > 0 ) {
    priceOld.value = logged[0].price_log_price_raw;
  }
}

watch(loggedPrices, (newValue, oldValue) => {
  updateOldPriceWithLoggedPrice();
});

onMounted(() => {
  priceStore.loggedPrices && updateOldPriceWithLoggedPrice();
});

</script>