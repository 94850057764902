import { ref, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProductListingStore } from '@/stores/product-listing';

export function useProductFilter(props) {
  const productListingStore = useProductListingStore();
  const { 
    productCount,
    selectedOptions,
    keywords, queryParams,
    tweakwiseFilterOptionsRestoredFromUrl,
    tweakwiseActive,
    selectedSortOrder
  } = storeToRefs(productListingStore);
  const filters = ref([]);
  const selectedFilters = ref([]);
  const showMobileFilter = ref(false);
  const chainedFilterUrl = ref(null);

  if (props.keywords) {
    keywords.value = props.keywords;
  }

  const updateTweakwiseFilters = (e) => {
    convertTweakwiseFilters(e.detail.facets);
  };
  
  document.addEventListener('vendreUpdateListingProductCount', updateProductCount);
  document.addEventListener('vendreChainedFilterUpdate', handleChainedFilterUpdate);
  
  if (props.closeMenuOnClickOutsideMenu) {
    document.addEventListener('click', closeFilterMenuOnClickOutsideMenu);
  }
  
  if (props.filtersTweakwise) {
    tweakwiseActive.value = true;
    convertTweakwiseFilters()
    document.addEventListener('tweakwiseFilterUpdate', updateTweakwiseFilters);
    if (!tweakwiseFilterOptionsRestoredFromUrl.value) {
      restoreSelectedTweakwiseOptionsFromURL();
    }
  } else if (props.filtersTwig) {
    buildFilters();
  }

  function saveFilterState() {
    const state = {};
    filters.value.forEach(filter => {
      state[filter.id] = {
        iscollapsed: filter.iscollapsed,
        showAllOptions: filter.showAllOptions
      };
    });
    return state;
  }

  function restoreFilterState(state) {
    filters.value.forEach(filter => {
      if (state.hasOwnProperty(filter.id)) {
        filter.iscollapsed = state[filter.id].iscollapsed;
        filter.showAllOptions = state[filter.id].showAllOptions;
      }
    });
  }

  function handleChainedFilterUpdate(e) {
    switch(e.detail.action) {
      case 'filterProducts':
        chainedFilterUrl.value = e.detail.data;
        CategoriesListing.fetchProducts(chainedFilterUrl.value);
        break;
      case 'clearAllFilters':
        chainedFilterUrl.value = null;
        selectedOptions.value = []
        break;
    }
  }
  
  function closeFilterMenuOnClickOutsideMenu(e) {
    let element = e.target;
    let parentLi = element.closest('li');
    if (!parentLi || !parentLi.hasAttribute('data-filter-menu')) {
      filters.value.forEach(filter => {
        filter.iscollapsed = true;
      });
    }
  }

  function updateProductCount(e) {
    productCount.value = e.detail;
  }
  
  function toggleMobileFilter() {
    General.dispatchGlobalEvent('VendreFilerSortMenu', 'close');
    showMobileFilter.value = !showMobileFilter.value;
  }
  
  function uncheckFilter(optionName) {
    selectedOptions.value = selectedOptions.value.filter(option => option.name !== optionName);
  }
  
  function selectedOptionsInFilterCount(filter) {
    let checkedOptionsCounter = 0;
    let isSlider = false;

    filter.options.forEach(option => {
      if (option.settings.selectiontype === 'slider') {
        isSlider = true;
      }
      
      if (option.settings.checked) {
        checkedOptionsCounter++;
      }
    });

    return !isSlider ? checkedOptionsCounter : checkedOptionsCounter / 2;
  }
  
  function resetFilters() {
    selectedOptions.value = [];
  }

  function existsInSelectedOptions(option) {
    return selectedOptions.value.some((selectedOption) => selectedOption.name === option.name);
  };
  
  function buildFilters() {
    props.filtersTwig.forEach(filter => {
      if (filter.options?.length) {
        filter.options.forEach(option => {
          if (option.name) {
            if (!option.id) {
              option.id = option.name;
            }

            if (option.settings.checked && !existsInSelectedOptions(option)) {
              selectedOptions.value.push(option);
            }
          }
        });
        filters.value.push(filter);
      }
    });
  }

  function restoreSelectedTweakwiseOptionsFromURL() {
    const searchURL = new URL(window.location);
    const paramsString = searchURL.search;
    const searchParams = new URLSearchParams(paramsString);
  
    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith('tweakwise[facets]')) {
        let option = {
          id: key,
          name: value,
          settings: {
            name: key,
            value: value
          }
        }
        if (!existsInSelectedOptions(option)) {
          selectedOptions.value.push(option);
        }
      } else if (key.startsWith('tweakwise[sort_fields]')) {
        selectedSortOrder.value = {
          label: value,
          value: value,
        }
      }
    }
    tweakwiseFilterOptionsRestoredFromUrl.value = true;
  }
 
  function convertTweakwiseFilters(filtersUpdate) {
    let facets;
    let tweakwiseFilters = props.filtersTweakwise;
    
    const previousState = saveFilterState();
    
    filters.value = [];

    if (!filtersUpdate) {
      facets = tweakwiseFilters.facets;
    } else {
      facets = filtersUpdate;
    }

    for (const key in facets) {
      let facet = facets[key];
      
      let filter = {
        id: facet.facetsettings.facetid,
        name: facet.facetsettings.title,
        options: [],
        iscollapsible: facet.facetsettings.iscollapsible,
        iscollapsed: facet.facetsettings.iscollapsed,
        nrOfShownOptions: facet.facetsettings.nrofshownattributes,
        isSearchable: facet.facetsettings.issearchable,
        searchQuery: '',
        searchPlaceholder: facet.facetsettings.searchplaceholder,
        showAllOptions: false,
        expandDropdownText: facet.facetsettings.expandtext,
        collapseDropdownText: facet.facetsettings.collapsetext,
        infoText: facet.facetsettings.infotext,
        isnrofresultsvisible: facet.facetsettings.isnrofresultsvisible,
      };
  
      facet.attributes.forEach((attribute, index) => {
        let option = {
          id: attribute.attributeid,
          image: attribute.imagetag,
          name: attribute.title,
          // colorCode: attribute.colorcode,
          colorCode: '#000000',
          showOption: facet.facetsettings.nrofshownattributes <= index ? false : true,
          settings: {
            selectiontype: facet.facetsettings.selectiontype,
            checked: attribute.isselected,
            disabled: false,
            name: `tweakwise[facets][${facet.facetsettings.urlkey}][]`,
            value: attribute.title,
            nrofresults: attribute.nrofresults,
          },
        }
        filter.options.push(option);

        if (option.settings.checked) {
          selectedFilters.value.push(option);
        }
      });
      filters.value.push(filter);
    }

    // Save the current collapsed state of filters to prevent it from being reset during updates
    restoreFilterState(previousState);
  }

  function updateRangeSlider(newRange) {
    const index = selectedOptions.value.findIndex(option => option.settings.name === newRange.settings.name);
    if (index !== -1) {
      selectedOptions.value[index] = newRange;
    } else {
      selectedOptions.value.push(newRange);
    }
    General.dispatchGlobalEvent('vendreUpdateProductListingQueryParams', queryParams.value);
  };

  const updateSelectedOptions = (newOption) => {
    const index = selectedOptions.value.findIndex(option => option.name == newOption.name);
    if (index !== -1) {
      selectedOptions.value.splice(index, 1);
    } else {
      selectedOptions.value.push(newOption);
    }

    General.dispatchGlobalEvent('vendreUpdateProductListingQueryParams', queryParams.value);
  }

  const filteredOptions = (filter) => {
    if (!filter.searchQuery) {
      return filter.options;
    } else {
      filter.showAllOptions = true;
      return filter.options.filter(option => 
        option.name.toLowerCase().includes(filter.searchQuery.toLowerCase())
      );
    }
  };

  const showOption = (filter, option) => {
    if (option.showOption || filter.showAllOptions || !props.filtersTweakwise || option.settings.checked) {
      return true;
    }
  };
  
  const showMoreButton = (filter) => {
    const nrOfCheckedOptions = filter.options.filter(option => option.settings.checked).length;
    const numberOfOptionsToShow = nrOfCheckedOptions > filter.nrOfShownOptions ? nrOfCheckedOptions : filter.nrOfShownOptions;
  
    if (numberOfOptionsToShow < filteredOptions(filter).length && props.filtersTweakwise) {
      return true;
    }
  };

  const formatName = (option) => {
    // If price
    if (option.settings.name.includes('ae-price')) {
      const formatedPriceWithCurrency = [];
      option.name.split('-').forEach((value) => {
        formatedPriceWithCurrency.push(window.VendreCurrencyFormatter(value));
      });
      return formatedPriceWithCurrency.join(' - ');
    }

    return option.name;
  };

  onUnmounted(() => {
    if (props.closeMenuOnClickOutsideMenu) {
      document.removeEventListener('click', closeFilterMenuOnClickOutsideMenu);
    }
    document.removeEventListener('vendreUpdateListingProductCount', updateProductCount);
    document.removeEventListener('vendreChainedFilterUpdate', handleChainedFilterUpdate);
  });

  return {
    toggleMobileFilter,
    uncheckFilter,
    showMobileFilter,
    filters,
    selectedFilters,
    selectedOptionsInFilterCount,
    selectedOptions,
    resetFilters,
    productCount,
    updateProductCount,
    keywords,
    updateRangeSlider,
    updateTweakwiseFilters,
    updateSelectedOptions,
    existsInSelectedOptions,
    restoreSelectedTweakwiseOptionsFromURL,
    filteredOptions,
    showOption,
    showMoreButton,
    formatName
  }
}