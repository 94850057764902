<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_668_360)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3125 15.9166H45.8334L43.3334 30.125C43.2707 30.4616 43.0931 30.7661 42.831 30.9865C42.5688 31.2069 42.2383 31.3294 41.8959 31.3333H19.3334C18.9923 31.3327 18.6625 31.2109 18.403 30.9895C18.1434 30.7681 17.9711 30.4617 17.9167 30.125L13.1459 3.95829C13.0637 3.5261 12.8338 3.13595 12.4955 2.85468C12.1572 2.57341 11.7316 2.41855 11.2917 2.41663H1.87504C1.36671 2.41663 0.879197 2.61856 0.519753 2.978C0.160308 3.33745 -0.041626 3.82496 -0.041626 4.33329C-0.041626 4.84162 0.160308 5.32914 0.519753 5.68858C0.879197 6.04803 1.36671 6.24996 1.87504 6.24996H9.72921L14.2084 30.7916C14.431 31.991 15.0643 33.0754 15.9996 33.8585C16.9348 34.6417 18.1136 35.0747 19.3334 35.0833H41.8959C43.1179 35.0828 44.3008 34.6527 45.2377 33.8681C46.1746 33.0836 46.8058 31.9946 47.0209 30.7916L50 14.5833C50.0556 14.2801 50.0431 13.9683 49.9636 13.6705C49.8841 13.3727 49.7394 13.0963 49.5401 12.8611C49.3409 12.6259 49.0919 12.4379 48.8111 12.3106C48.5304 12.1833 48.2249 12.1199 47.9167 12.125H20.3125C19.8153 12.125 19.3383 12.3225 18.9867 12.6741C18.6351 13.0258 18.4375 13.5027 18.4375 14C18.4348 14.2489 18.4811 14.496 18.5738 14.7271C18.6666 14.9581 18.8039 15.1687 18.9781 15.3467C19.1522 15.5246 19.3596 15.6666 19.5886 15.7644C19.8176 15.8622 20.0636 15.9139 20.3125 15.9166Z" fill="black"/>
  <path d="M19.1667 47.5833C21.4679 47.5833 23.3333 45.7179 23.3333 43.4167C23.3333 41.1155 21.4679 39.25 19.1667 39.25C16.8655 39.25 15 41.1155 15 43.4167C15 45.7179 16.8655 47.5833 19.1667 47.5833Z" fill="black"/>
  <path d="M42.0833 47.5833C44.3845 47.5833 46.25 45.7179 46.25 43.4167C46.25 41.1155 44.3845 39.25 42.0833 39.25C39.7821 39.25 37.9166 41.1155 37.9166 43.4167C37.9166 45.7179 39.7821 47.5833 42.0833 47.5833Z" fill="black"/>
  </g>
  <defs>
  <clipPath id="clip0_668_360">
  <rect width="50" height="50" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>