<template>
  <div class="quick-add-to-cart">
    <form 
      action="/?action=add_product" 
      method="post" 
      @submit.prevent.stop="submit" 
      ref="form"
    >
      <input type="hidden" name="products_id" v-model="productId">
      <input type="hidden" name="model" v-model="model">

      <div class="row">
          
        <!-- EAN / Article number search field -->
        <div 
          v-if="searchFields == 'model_and_name' || searchFields == 'model'"
          class="medium-3 columns autocomplete-wrapper"
        >
          <label>
            {{ labelModel }}
            <input 
              type="text" 
              v-model="model" 
              @input="search('model')" 
              @keydown.prevent.down="selectNextProduct" 
              @keydown.prevent.up="selectPreviousProduct" 
              @keydown.prevent.enter="selectSelectedProduct" 
              ref="modelInput"
            >
          </label>
          
          <ul 
            v-if="autocomplete && products?.length && searchUsingField === 'model'" 
            class="autocomplete-results" 
            ref="modelResults" 
            v-cloak
          >
            <li 
              v-for="product in products" 
              class="autocomplete-item" 
              :class="{ active: (selectedDropdownProduct !== null && product.id === selectedDropdownProduct.id)}"
              :title="product.model" 
              @click="setProduct(product)" 
              ref="product"
            >
              {{ product.model }}<span v-if="product.ean?.length"> / {{ product.ean }}</span>
            </li>
          </ul>
        </div>
        
        <!-- Name search field -->
        <div 
          v-if="(searchFields == 'model_and_name' || searchFields == 'name') && autocomplete"
          class="medium-4 columns autocomplete-wrapper"
        >
          <label>
            {{ labelName }}
            <input 
              type="text" 
              v-model="name" 
              @input="search('name')" 
              @keydown.prevent.down="selectNextProduct" 
              @keydown.prevent.up="selectPreviousProduct" 
              @keydown.prevent.enter="selectSelectedProduct" 
              ref="nameInput"
            >
          </label>
          
          <ul 
            v-if="autocomplete && products && searchUsingField === 'name'" 
            class="autocomplete-results" 
            ref="nameResults" 
            v-cloak
          >
            <li 
              v-for="product in products" 
              class="autocomplete-item" 
              :class="{active: (selectedDropdownProduct !== null && product.id === selectedDropdownProduct.id)}" 
              :title="product.name" 
              @click="setProduct(product)" 
              ref="product"
            >
              {{ product.name }}
            </li>
          </ul>
        </div>
        
        <!-- Quantity input -->
        <div class="medium-5 columns end">
          <label>{{ labelQuantity }}</label>
          <div class="row">
            <div class="small-4 columns">
              <input 
                v-model="quantity" 
                type="number" 
                name="quantity" 
                min="1" 
                ref="quantityInput"
              >
            </div>
            
            <div class="small-8 columns">
              <button 
                type="submit" 
                class="button primary expanded" 
                :disabled="formIsDisabled"
                ref="submitButton"
                data-quick-add-submit-button
              >
                + {{ $translate('IMAGE_BUTTON_IN_CART') }}
              </button>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed, onBeforeUnmount } from 'vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';

export default {
  props: {
    initAutocomplete: {
      type: Boolean,
      required: true
    },
    labelModel: {
      type: String,
      required: false,
    },
    labelName: {
      type: String,
      required: true
    },
    labelQuantity: {
      type: String,
      required: true
    },
    searchFields: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const searchFields = props.searchFields;
    const form = ref();
    const modelInput = ref();
    const modelResults = ref();
    const nameResults = ref();
    const quantityInput = ref();
    const submitButton = ref();
    const autocomplete = props.initAutocomplete;
    const model = ref('');
    const name = ref('');
    const productId = ref(null);
    const products = ref(null);
    const product = ref();
    const quantity = ref(1);
    const searchTimeoutId = ref(null);
    const searchUsingField = ref(null);
    const selectedDropdownProduct = ref(null);

    const shoppingCartStore = useShoppingCartStore();

    const formIsDisabled = computed(() => productId.value === null || quantity.value < 1);
    
    function search(searchField) {
      searchUsingField.value = searchField;
      selectedDropdownProduct.value = null;

      clearTimeout(searchTimeoutId.value);
      searchTimeoutId.value = setTimeout(function() {
        let formData = new FormData();
        formData.append('hide_ajax_loader',  true);
        
        if (searchField === 'model') {
          if (model.value.length < 3) {
            return;
          }
          formData.append('model', model.value);
        }
        
        if (searchField === 'name') {
          if (name.value.length < 3) {
            return;
          }
          formData.append('name', name.value);
        }

        if (!autocomplete) {
          formData.append('full_match',  true);
        }
        
        const serializedFormData = new URLSearchParams(formData).toString();
        fetch(`/ajax.php?mode=products_autocomplete&${serializedFormData}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            products.value = [];
            products.value = data;
            
            if (products.value?.length) {
              selectedDropdownProduct.value = products.value[0];
              
              if (!autocomplete) {
                setProduct(products.value[0]);
              }
            }
          });
      }, 200);
    }
    
    function setProduct(product) {
      productId.value = product.id;
      if (autocomplete) {
        model.value = product.model;
        name.value = product.name;
        quantityInput.value.focus();
      }
      products.value = [];
    }

    function selectNextProduct() {
      let foundSelectedProduct = null;
      for (let key in products.value) {
        if (foundSelectedProduct) {
          selectedDropdownProduct.value = products.value[key];
          scrollSelectedProductIntoView();
          break;
        }

        if (products.value[key].id === selectedDropdownProduct.value.id) {
          foundSelectedProduct = true;
        }
      }
    }

    function selectPreviousProduct() {
      for (let key in products.value) {
        if (
          products.value[key].id === selectedDropdownProduct.value.id &&
          typeof products.value[key-1] !== 'undefined'
        ) {
          selectedDropdownProduct.value = products.value[key-1];
          scrollSelectedProductIntoView();
          break;
        }
      }
    }

    function scrollSelectedProductIntoView() {
      for (let key in products.value) {
        if (products.value[key].id === selectedDropdownProduct.value.id) {
          product.value[key].scrollIntoView(false);
        }
      }
    }

    function selectSelectedProduct() {
      if (selectedDropdownProduct.value !== null) {
        setProduct(selectedDropdownProduct.value);
      }
    }

    function submit() {
      if (!formIsDisabled.value) {

        const product = {
          id: productId.value,
          quantity_diff: quantity.value,
          attributes: {}
        };
        shoppingCartStore.setProducts(product)
        .catch(error => {
          errMsg.value = error;

          setTimeout(() => {
            errMsg.value = null;
          }, 5000);
        })
        
        model.value = '';
        name.value = '';
        productId.value = null;
        quantity.value = 1;

        // Reset focus
        if (typeof modelInput !== 'undefined') {
          modelInput.value.focus();
        } else if (typeof nameInput !== 'undefined') {
          nameInput.value.focus();
        }
      }
    }
    
    function clickEventListener(e) {
      // Close autocompletes on click outside
      if (
        (
          typeof modelResults.value !== 'undefined' &&
          !modelResults.value?.contains(e.target)
        ) || (
          typeof nameResults.value !== 'undefined' &&
          !nameResults.value?.contains(e.target)
        )
      ) {
        products.value = [];
      }
    }

    window.addEventListener('click', clickEventListener);

    onBeforeUnmount(() => {
      window.removeEventListener('click', clickEventListener);
    });

    return {
      searchFields,
      form,
      modelInput,
      modelResults,
      nameResults,
      quantityInput,
      submitButton,
      autocomplete,
      productId,
      name,
      quantity,
      products,
      product,
      searchUsingField,
      model,
      search,
      formIsDisabled,
      selectedDropdownProduct,
      selectNextProduct,
      selectPreviousProduct,
      selectSelectedProduct,
      setProduct,
      submit
    }
  }
}
</script>