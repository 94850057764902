<template>
  <div class="cart-container">
    <div class="header">
      <div class="heading-container">
        <div class="heading">{{ $translate('BOX_HEADING_SHOPPING_CART') }}</div>
        <div class="sub-heading">
          {{ $translate('YOU_HAVE_X_ITEMS_IN_YOUR_SHOPPING_CART', {
            productCount: shoppingCartStore.cartCount
          }) }}
        </div>
      </div>

      <button @click="closeCart" class="close" aria-label="Close cart">
        <CloseIcon />
      </button> 
    </div>

    <div class="content">
      <ShoppingCartItem
        v-for="product in shoppingCartStore.products"
        :key="`${Date.now()}_${product.id}`"
        :product="product"
      />
    </div>

    <div class="footer">
      <div v-if="showLeftToFreeShipping" class="shipping-info-container">
        <div>
          <span>Frakt</span>
        </div>
        
        <LeftToFreeShipping class="left-to-free-shipping-component" />
      </div>
      
      <div v-if="shoppingCartStore.products?.length">
        <div class="total-container">
          <span class="text-sum">{{ $translate('BOX_SHOPPING_CART_SUB_TOTAL_BOTTOM') }}</span>
          <span class="total">{{ shoppingCartStore.total }}</span>
        </div>
  
        <a href="/checkout.php" class="button primary expanded">
          {{ $translate('BUTTON_SHOPPING_CART') }}
        </a>
      </div>
    </div>

  </div>
</template>

<script setup>
import CloseIcon from '@/components/icons/CloseIcon.vue';
import ShoppingCartItem from '@/components/ShoppingCartItem.vue';
import LeftToFreeShipping from '@/components/LeftToFreeShipping.vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';

const showLeftToFreeShipping = window.vendre_config.general.show_left_to_free_shipping;

const shoppingCartStore = useShoppingCartStore();
  
const closeCart = () => {
  document.querySelector('[data-exit-off-canvas]').click();
}
</script>