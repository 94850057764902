import { defineStore } from 'pinia';
import { ref, onMounted, onUnmounted } from 'vue';

export const useProductStore = defineStore('product', () => {
  const currentDomProductIds = ref(null);

  // Get all product ids and crate array with unique values to store in Pinia.
  const setProductIds = () => {
    let domProducts = document.querySelectorAll('[data-product-id]');
    let productIds = [];

    domProducts.forEach((domProduct) => {
      productIds.push(+domProduct.getAttribute('data-product-id'))
    });

    let uniqueProductIds = [...new Set(productIds)];
    currentDomProductIds.value = uniqueProductIds;
  };

  onMounted(() => {
    ['vendreDOMContentReplaced', 'vendreDOMContentAppended', 'DOMContentLoaded'].forEach(function (event) {
      document.addEventListener(event, function () {
        setProductIds();
      });
    });
  });

  onUnmounted(() => {
    ['vendreDOMContentReplaced', 'vendreDOMContentAppended', 'DOMContentLoaded'].forEach(function (event) {
      document.removeEventListener(event);
    });
  });

  return {
    currentDomProductIds
  }
});
