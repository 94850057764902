import { defineStore, storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { usePriceService } from '@/services/price-service';
import { useProductStore } from '@/stores/product-store.js';

export const usePriceStore = defineStore('price', () => {
  const priceService = usePriceService();
  const loggedPrices = ref(null);
  const checkedProductIdsForLoggedPrice = ref(null);
  const productStore = useProductStore();
  const { currentDomProductIds: productIds } = storeToRefs(productStore);

  // get initial products on page load 
  const initPriceStore = async () => {
    if (!productIds.value || productIds.value.length < 1) {
      return;
    }
    await getLoggedPrices(productIds.value);
  }

  watch(productIds, (newValue, oldValue) => {
    getLoggedPrices(productStore.currentDomProductIds);
  });

  // keep track of all fetched ids to avoid fetching more than once. 
  const checkProductIds = async (productIds) => {
    productIds.forEach((id) => {
      if (!checkedProductIdsForLoggedPrice.value) {
        checkedProductIdsForLoggedPrice.value = productIds;
      } else if (!checkedProductIdsForLoggedPrice.value.includes(id)) {
        checkedProductIdsForLoggedPrice.value.push(id);
      }
    });
  }

  const getLoggedPrices = async (productIds) => {
    try {
      let queryIds = productIds;

      if (checkedProductIdsForLoggedPrice.value) {
        queryIds = productIds.filter((id) => {
          return !checkedProductIdsForLoggedPrice.value.includes(id);
        });
      }

      if (queryIds.length < 1) {
        return;
      }
  
      const priceLogQueryParams = await createPriceLogQueryParams(queryIds);
      const res = await priceService.getLoggedPrices(priceLogQueryParams);
      const data = await res.json();

      if (data) {
        if (loggedPrices.value) {
          const filteredProducts = Object.values(data).filter(({ product_id: id1 }) => {
            return !loggedPrices.value.some(({ product_id: id2 }) => id2 === id1);
          });

          loggedPrices.value = [...loggedPrices.value, ...filteredProducts];
        } else {
          loggedPrices.value = Object.values(data);
        }
      }

      checkProductIds(productIds);
    } catch(e) {
      throw new Error(e);
      // TODO handleApiError
    }
  }

  const createPriceLogQueryParams = async (productIds) => {
    let queryString = '';
    productIds.forEach(id => queryString += `id[]=${id}&`);
    return queryString;
  }

  const findLoggedPrice = (productId) => {
    if (!loggedPrices.value) {
      return;
    }
    return loggedPrices.value.find((item) => item.product_id == productId );
  }

  initPriceStore();

  return {
    loggedPrices,
    getLoggedPrices,
    findLoggedPrice
  }
});