<template>
  <div class="product-variant-buy-list row column">
    <div 
      v-for="(product, idx) in productVariants" 
      :key="product.id"
      class="product"
    >  
      <div class="left">
        <div>
          {{ product.model }}
        </div>

        <ProductStockStatus 
          :allow-checkout="product.stock_allow_checkout"
          :stock="product.quantity"
        />
      </div>

      <div class="right">
        <div class="price-wrapper">
          <div v-if="!product.price_special">
            {{ product.price }}
          </div>

          <div
            v-if="product.price_special && product.price > product.price_special"
            class="old"
          >
            {{ product.price }}
          </div>

          <div 
            v-if="product.price_special"
            :class="{ new: product.price_special < product.price }"
          >
            {{ product.price_special }}
          </div>
        </div>
      
        <ProductQuantityInput
          v-model="products[idx].quantity_diff"
          :lowest-number="0"
          :hide-spin-buttons="true"
        />
      </div>
    </div>

    <div class="buy-button">
      <p v-if="errorMessage" class="err">
        {{ errorMessage }}
      </p>
      <button 
        type="button"
        class="button primary"
        @click="buyProducts"
      >
        {{ $translate('PRODUCT_BUTTON_BUY') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue';
import ProductQuantityInput from '@/components/form/ProductQuantityInput.vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
import { usePriceStore } from '@/stores/price-store.js';
const shoppingCartStore = useShoppingCartStore();
const priceStore = usePriceStore();

const errorMessage = ref();

const props = defineProps({
  variants: {
    required: true,
    type: Array
  }
});

// Array for v-model
const products = reactive([]);
let productIds = [];
props.variants.forEach(product => {
  productIds.push(product.id);
  let mappedProduct = {
    id: product.id,
    quantity_diff: 0
  };

  products.push(mappedProduct);
});

const productVariants = ref(props.variants);

onMounted(async() => {
  await priceStore.getLoggedPrices(productIds);
  const productVariantsWithUpdatedPrices = productVariants.value.map((variant) => {
    if (priceStore.loggedPrices) {
      let product = priceStore.loggedPrices.find((item) => item.product_id == variant.id);
      if (product) {
        variant.price = product.price_log_price;
      }
    }
    return variant;
  });
  productVariants.value = productVariantsWithUpdatedPrices;
});

function buyProducts() {
  let productsToBuy = products.filter(product => product.quantity_diff > 0);

  shoppingCartStore.setProducts(productsToBuy)
    .then(() => {
      // Success action
    })
    .catch(e => {
      errorMessage.value = "En eller flera produkter kunde inte läggas till"
      setTimeout(() => {
        errorMessage.value = null;
      }, 5000);
    })
}
</script>