<template>
  <input 
    v-model="quantity"
    @change="validateProductQuantityChange"
    :step="step"
    type="number"
    name="quantity"
    ref="quantityInput"
    :class="{ 'hide-spin-buttons': props.hideSpinButtons }"
    :title="$translate('TABLE_HEADING_QUANTITY')"
    min="0"
  />
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  step: {
    type: Number,
    required: false,
    default: 1
  },
  lowestNumber: {
    type: Number,
    required: false,
    default: 1
  },
  hideSpinButtons: {
    type: Boolean,
    required: false,
    default: false
  }
});

const quantity = defineModel();
const quantityInput = ref();

const decreaseQuantity = () => {
  quantity.value--;
}

const increaseQuantity = () => {
  quantity.value++;
}

defineExpose({
  increaseQuantity,
  decreaseQuantity
})

const validateProductQuantityChange = () => {      
  /** Here you can add validation to quantity changes.
   * For example if you want to add logic to handle purchase intervals
    */
  if (quantity.value < props.lowestNumber) {
    quantity.value = props.lowestNumber;
  }
}
</script>

<style lang="scss" scoped>
.hide-spin-buttons {
  /* Works for Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}
</style>