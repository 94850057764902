<template>
  <div class="product-page-price-wrapper">
    <div class="prices">
      <div 
        v-if="discountPrice"
        class="current-price"
        :class="{ 'discount-price': displayRedPrice }"
      >
        {{ discountPrice }}
      </div>

      <div 
        v-if="regularPrice && !discountPrice"
        class="current-price"
      >
        {{ regularPrice }}
      </div>
      
      <div 
        v-if="regularPrice && discountPrice && displayRedPrice"
        :class="{ 'has-discount': discountPrice }"
      >
        {{ regularPrice }}
      </div>
    </div>

    <div v-if="discountPrice && displayRedPrice">
      <span class="lowest-price-thirty-days">
        {{ $translate('LOWEST_PRICE_DURING_PREVIOUS_30_DAYS') }}
        {{ regularPrice }}
        <span
          v-if="discountPercentage"
          class="discount-percentage"
        >
          {{ discountPercentage }}
        </span>
        <tooltip
          :text="$translate('LOWEST_PRICE_DURING_PREVIOUS_30_DAYS_TOOLTIP')"
          class="info-icon"
        >
          <InfoIcon />
        </tooltip>  
      </span>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, computed } from 'vue';
import InfoIcon from '@/assets/icons/information-blue-rounded.svg?component';
import { useProductPrice } from '@/composables/product-price.js';

export default {
  props: {
    showVat: {
      type: Boolean,
      required: true
    },
    updateOnChange: {
      type: Boolean,
      required: false
    },
    productData: {
      type: Object,
      required: true
    },
    handlePriceLogUpdate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    InfoIcon
  },
  setup(props) {
    const {
      intialPriceLoaded,
      price,
      handleUpdatedProductConfiguratorPrice,
      getPrice,
      discountPrice,
      regularPrice,
      discountPercentage,
      vatText,
      displayRedPrice
    } = useProductPrice(props.productData, props);
    
    // Lowest price in 30 days
    const lowestPrice30Days = computed(() => {
      if (props.priceLogPrice) {
        return props.priceLogPrice;
      }

      return price.value;
    });

    onMounted(async() => {
      if (props.updateOnChange) {
        await getPrice();
        let productForm = document.querySelector('form[name=add_product]');

        if (productForm) {
          productForm.addEventListener('input', function (e) {
            getPrice();
          });
        }
      }

      document.addEventListener(
        'vendreProductPageProductConfiguratorPriceUpdated',
        handleUpdatedProductConfiguratorPrice
      );

      intialPriceLoaded.value = true;
    });

    onUnmounted(() => {
      document.removeEventListener(
        'vendreProductPageProductConfiguratorPriceUpdated',
        handleUpdatedProductConfiguratorPrice
      );
    });

    return {
      InfoIcon,
      vatText,
      regularPrice,
      lowestPrice30Days,
      discountPercentage,
      discountPrice,
      displayRedPrice
    }
  }
}
</script>