<template>
  <div class="filter-container">
    <div class="filter">
      <button @click="toggleMobileFilter" class="mobile-filter-toggle-button">
        <span>{{ $translate('TEXT_FILTER') }}</span>
        <FilterIcon />
      </button>

      <div 
        v-show="showMobileFilter" 
        @click="toggleMobileFilter"
        class="filter-mobile-overlay" 
      />

      <div 
        class="filter-dropdowns" 
        :class="{ open: showMobileFilter }"
      >
        <button 
          @click="toggleMobileFilter"
          class="mobile-filter-toggle-button mobile-filter-top-button" 
        >
          <span>{{ $translate('TEXT_FILTER') }}</span>
          <CloseIcon />
        </button>
        
        <ul class="list">  
          <li v-for="filter in filters" :key="filter.id" data-filter-menu>
            <button 
              @click="handleFilterTopDropdowns(filter.id)" 
              class="btn"
              :class="{ open: selectedFilters.includes(filter.id) }"
            >
              <span>
                {{ filter.name }} 
              </span>
              <span v-if="selectedOptionsInFilterCount(filter) > 0" class="number-of-filters-checked">
                {{ selectedOptionsInFilterCount(filter) }}
              </span>
              <ChevronUpIcon v-if="!filter.iscollapsed"/>
              <ChevronDownIcon v-else/>
            </button>

            <div 
              v-show="!filter.iscollapsed"
              class="filter-dropdown"
            > 

              <p v-if="filter.infoText" class="info-text">
                {{ filter.infoText }}
              </p>

              <input
                v-if="filter.isSearchable"
                v-model="filter.searchQuery"
                class="search-option"
                type="text"
                :placeholder="filter.searchPlaceholder"
              />
              <div 
                v-for="option, index in filteredOptions(filter)"
                v-show="showOption(filter, option)"
                :key="`${index}-${option.settings.name}`"
              >
                <!-- checkbox option -->
                <label v-if="option.settings.selectiontype === 'checkbox' || option.settings.selectiontype === 'color' || !filtersTweakwise">
                  <div class="check">
                    <input
                      type="checkbox"
                      :value="option"
                      :checked="existsInSelectedOptions(option)"
                      @change="updateSelectedOptions(option)"
                    />
                    <span class="checkmark" />
                    <span class="name">{{ option.name }}</span>
                    <span 
                      v-if="filter.isnrofresultsvisible"
                      class="nr-of-results"
                    >({{ option.settings.nrofresults }})</span>
                  </div>
                </label>
                
                <!-- color option -->
                <!-- <label v-if="option.settings.selectiontype === 'color'">
                  <button
                    @click="updateSelectedOptions(option)"
                    :class="{ selected: existsInSelectedOptions(option) }"
                    class="color-palette-button"
                  >
                    <span
                      :style="{backgroundColor: option.colorCode }"
                      class="color-palette"></span>
                    <span class="name">{{ option.name }}</span>
                    <span 
                      v-if="filter.isnrofresultsvisible"
                      class="nr-of-results"
                    >({{ option.settings.nrofresults }})</span>
                  </button>
                </label> -->

                <!-- range slider option -->
                <RangeSlider
                  v-if="option.settings.selectiontype == 'slider' && index < 1 && filtersTweakwise"
                  @newRange="newRange"
                  :option="option"
                  :minVal="filter.options[0].settings.value"
                  :maxVal="filter.options[1].settings.value"
                  :constMinVal="filter.options[2]?.settings.value"
                  :constMaxVal="filter.options[3]?.settings.value"
                />
              </div>

              <button
                v-if="showMoreButton(filter)"
                @click="filter.showAllOptions = !filter.showAllOptions"
                class="show-all-options-button"
              >
                <span>{{ filter.showAllOptions ? filter.collapseDropdownText : filter.expandDropdownText }}</span>
              </button>
            </div>
          </li>
        </ul>

        <!-- List of selected filters -->  
        <div v-if="selectedOptions.length" class="selected-filters">
          <button
            v-for="(option, index) in selectedOptions"
            @click="uncheckFilter(option.name)"
            class="filtered-button" 
            :key="`${index}-${option.id}`"
          >
            <span>{{ formatName(option) }}</span>
            <CloseIcon />
          </button>
          
          <button 
            @click="resetFilters"
            class="clear-filters-button" 
          >
            <span>{{ $translate('BUTTON_CLEAR_FILTERS') }}</span>
          </button>
        </div>
        
        <button 
          @click="toggleMobileFilter"
          class="button primary show-products-button" 
        >
          <span>{{ $translate('SHOW_PRODUCTS') }} {{ productCount }}</span>
        </button>
      </div>
    </div>
  </div>  
</template>

<script setup>
import { useProductFilter } from '@/composables/product-listing-filter.js';

const props = defineProps({
  filtersTweakwise: {
    type: Object,
    required: false
  },
  filtersTwig: {
    type: Array,
    required: false,
  },
  productCount: {
    type: Number,
    required: true
  },
  closeMenuOnClickOutsideMenu: {
    type: Boolean,
    required: true
  },
  keywords: {
    type: String,
    required: false
  }
  });

  const {
    toggleMobileFilter,
    showMobileFilter,
    filters,
    filteredOptions,
    selectedFilters,
    selectedOptionsInFilterCount,
    selectedOptions,
    resetFilters,
    productCount,
    uncheckFilter,
    updateRangeSlider,
    updateSelectedOptions,
    existsInSelectedOptions,
    showOption,
    showMoreButton,
    formatName
  } = useProductFilter(props);

  const handleFilterTopDropdowns = (filterId) => {
    filters.value.forEach(filter => {
      filter.iscollapsed = filter.id !== filterId ? true : !filter.iscollapsed;
    });
  };

  if (!props.filtersTweakwise) {
    handleFilterTopDropdowns(0);
  }

  const newRange = (option) => {
    updateRangeSlider(option);
  };
</script>