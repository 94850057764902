import { ref, computed, inject, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { usePriceStore } from '@/stores/price-store.js';


export function useProductPrice(priceAttributes, props) {
  const { $translate } = inject('i18n');
  
  /** This is to prevent the wrong price on product with attributes 
     * since attributes adds cost to product */
  const intialPriceLoaded = ref(false);

  // Price attributes
  const productConfiguratorPrice = ref(null);
  const price = ref(priceAttributes.price);
  const priceRaw = ref(priceAttributes.price_raw);
  const priceSpecial = ref(priceAttributes.price_special);
  const priceSpecialRaw = ref(priceAttributes.price_special_raw);
  const pricePerUnit = ref(priceAttributes.price_per_unit);
  const priceDiscountPercentage = ref(priceAttributes.price_discount_percentage);
  const tax = ref(priceAttributes.tax);
  const finalPriceExVatRaw = ref(priceAttributes.final_price_ex_vat_raw);
  const priceStore = usePriceStore();
  const loggedPrice = ref(null);
  const handlePriceLog = ref(props.handlePriceLogUpdate);
  const { loggedPrices } = storeToRefs(priceStore);

  const getLoggedPrice = async () => {
    const productId = props.productData.id;
    await priceStore.getLoggedPrices([productId]);
    const product = priceStore.findLoggedPrice(productId);
    if (!product) {
      return;
    }
    loggedPrice.value = product.price_log_price_raw;
  }

  // only fetch price logs if on page with single product NOT for listing or multiple product pages.
  if (priceSpecial.value && handlePriceLog.value) {
    getLoggedPrice();
  }

  if (priceSpecial.value && !handlePriceLog.value) {
    const product = priceStore.loggedPrices && priceStore.findLoggedPrice(props.productData.id);
    loggedPrice.value = product && product.price_log_price_raw;
  }

  // watch when loggedPrices is updated in the store and update product price if there is a logged price
  watch(loggedPrices, (newValue, oldValue) => {
    if (newValue) {
      if (priceSpecial.value && !props.handlePriceLogUpdate) {
        let productToUpdate = priceStore.loggedPrices.find((item) => {
          return item.product_id == props.productData.id
        });
        if (productToUpdate) {
          loggedPrice.value = productToUpdate.price_log_price_raw;
        }
      }
    }
  });

  const productTax = computed(() => {
    return (tax.value / 100) + 1;
  });
  
  const discountPrice = computed(() => {
    if (priceSpecialRaw.value) {
      return priceSpecial.value;
    }
  });

  const discountPriceRaw = computed(() => {
    if (priceSpecialRaw.value) {
      return priceSpecialRaw.value;
    }
  });

  const displayRedPrice = computed(() => {
    if (loggedPrice.value) {
      return priceSpecialRaw.value < loggedPrice.value;
    }
    return priceSpecialRaw.value < priceRaw.value;
  });

  const regularPrice = computed(() => {
    if (loggedPrice.value) {
      return window.VendreCurrencyFormatter(loggedPrice.value);
    } else {
      if (!intialPriceLoaded.value) {
        return '';
      }

      if (productConfiguratorPrice.value !== null) {
        return productConfiguratorPrice.value;
      }

      if (pricePerUnit.value) {
        return pricePerUnit.value;
      } else {
        return price.value;
      }
    }
  });

  const discountPercentage = computed(() => {
    let discount = null;

    if (loggedPrice.value) {
      const discountDecimals = (loggedPrice.value - priceSpecialRaw.value ) / loggedPrice.value;
      discount = Math.round(discountDecimals * 100);
    } else if (priceDiscountPercentage.value) {
      discount = priceDiscountPercentage.value;
    }

    return discount ? `(-${discount}%)` : null;
  });

  const vatText = computed(() => {
    if (!props) {
      return '';
    }

    if (props.showVat) {
      return $translate('EXCL_VAT') + ' ' + tooltipPrice('exvat');
    }

    return $translate('INCL_VAT') + ' ' + tooltipPrice('incvat');
  });

  const handleUpdatedProductConfiguratorPrice = (event) => {
    productConfiguratorPrice.value = event.detail.price;
  }

  function tooltipPrice(type) {
    let finalPrice;
    let productPrice;

    if (productConfiguratorPrice.value) {
      // Product configurator returns price as string - hence this conversion to float.
      const pattern = /[-,0-9]+/
      let productConfiguratorPriceRaw = productConfiguratorPrice.value.match(pattern)[0];
      productConfiguratorPriceRaw = productConfiguratorPriceRaw.replace(',', '.');
      productPrice = parseFloat(productConfiguratorPriceRaw);
    } else {
      productPrice = discountPriceRaw.value ? priceSpecialRaw.value : priceRaw.value;
    }

    if (type == 'incvat') {
      finalPrice = productPrice * productTax.value;
    } else {
      finalPrice = productPrice / productTax.value;
    }

    return window.VendreCurrencyFormatter(finalPrice);
  }

  async function getPrice(productId) {
    let formElements = null;

    if (productId) { // categories listing
      formElements = document.querySelector(`form[name=product_${productId}]`);
    } else { // product page
      formElements = document.querySelector('form[name=add_product]');
    }

    const formData = new FormData(formElements);
    let serializedFormData = new URLSearchParams(formData).toString();

    if (
        formData.get('quantity') === undefined || formData.get('quantity') === null ||
        formData.get('products_id') === undefined || formData.get('products_id') === null
    ) {
      return;
    }

    fetch(`/ajax.php?mode=getProductPrice&hide_ajax_loader=true&${serializedFormData}`, {
      headers: {
        'Accept': 'application/json',
      },
      method: 'POST'
    })
      .then((response) => response.json())
      .then((data) => {
        let price = null;

        if (window.vendre_config.product.show_total_final_price) {
          if (data.total_final_price_raw) {
            price = data.total_final_price_raw;
          }
        } else {
          if (data.final_price_per_unit) {
            price = data.final_price_per_unit;
          } else if (data.final_price_raw) {
            price = data.final_price_raw;
          }
        }

        if (discountPrice.value) {
          priceSpecialRaw.value = price;
          priceSpecial.value = window.VendreCurrencyFormatter(price);
          
          const priceDiff = priceRaw.value - priceSpecialRaw.value;
          priceDiscountPercentage.value = Math.round((priceDiff / priceRaw.value) * 100);
        } else {
          priceRaw.value = price;
          if (pricePerUnit.value) {
            pricePerUnit.value = window.VendreCurrencyFormatter(price);
          } else {
            price.value = window.VendreCurrencyFormatter(price);
          }
        }
      })
      .catch(error => {
        //
      });
  };
  
  return {
    getPrice,
    vatText,
    intialPriceLoaded,
    price,
    handleUpdatedProductConfiguratorPrice,
    discountPrice,
    discountPriceRaw,
    finalPriceExVatRaw,
    regularPrice,
    discountPercentage,
    displayRedPrice
  }
}