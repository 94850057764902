<template>
  <div class="range-slider-container">
    <div ref="sliderContainer" class="slider-container" @click="setSliderValue($event)">
      <div class="slider-track"></div>
      <div class="slider-thumb min-thumb" :style="{ left: `${minPercent}%` }" @mousedown="startDrag('min', $event)"></div>
      <div class="slider-thumb max-thumb" :style="{ left: `${maxPercent}%` }" @mousedown="startDrag('max', $event)"></div>
    </div>
    <div class="sum-container">
      <span>{{ valueFormatted(minValue) }}</span>
      <span>{{ valueFormatted(maxValue) }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  minVal: String,
  maxVal: String,
  constMinVal: String,
  constMaxVal: String,
  option: Object,
});

const sliderContainer = ref(null);
const dragging = ref(null);
const minValue = ref(props.minVal);
const maxValue = ref(props.maxVal);
const constMinVal = ref(props.constMinVal ? props.constMinVal : props.minVal);
const constMaxVal = ref(props.constMaxVal ? props.constMaxVal : props.maxVal);

const calculatePercent = (value) => {
  return ((parseInt(value) - constMinVal.value) / (constMaxVal.value - constMinVal.value)) * 100;
};

const minPercent = ref(calculatePercent(parseInt(minValue.value), constMinVal.value, constMaxVal.value));
const maxPercent = ref(calculatePercent(parseInt(maxValue.value), constMinVal.value, constMaxVal.value));

watch([minValue, maxValue], () => {
  minPercent.value = calculatePercent(minValue.value);
  maxPercent.value = calculatePercent(maxValue.value);
}, { deep: true });

const setSliderValue = (event) => {
  if (!sliderContainer.value || !dragging.value) return;
  
  const rect = sliderContainer.value.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const totalWidth = rect.width;
  const range = parseInt(constMaxVal.value) - parseInt(constMinVal.value);
  const minGap = range * 0.05;
  let newValue = ((x / totalWidth) * range) + parseInt(constMinVal.value);

  if (dragging.value === 'min') {
    newValue = Math.min(newValue, parseInt(maxValue.value) - minGap);
    minValue.value = Math.max(newValue, parseInt(constMinVal.value)).toString();
  } else if (dragging.value === 'max') {
    newValue = Math.max(newValue, parseInt(minValue.value) + minGap);
    maxValue.value = Math.min(newValue, parseInt(constMaxVal.value)).toString();
  }
};

const startDrag = (thumbType, event) => {
  event.preventDefault();
  dragging.value = thumbType;
  document.addEventListener('mousemove', setSliderValue);
  document.addEventListener('mouseup', stopDrag);
};

const emit = defineEmits(['update:newRange']);

const updateRange = () => {
  const paramValue = `${Math.round(minValue.value)}-${Math.round(maxValue.value)}`;
  const newRange = {
    name: paramValue,
    settings: {
      name: props.option.settings.name,
      value: paramValue,
    }
  }

  emit('newRange', newRange);
};

const valueFormatted = (value) => {
  Math.round(value);

  // If Price
  if (props.option.settings.name.includes('ae-price')) {
    return window.VendreCurrencyFormatter(value);
  }

  return value;
};

const stopDrag = () => {
  document.removeEventListener('mousemove', setSliderValue);
  document.removeEventListener('mouseup', stopDrag);
  dragging.value = null;

  updateRange();
};
</script>

